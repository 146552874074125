<template>
  <button
    class="border border-gray-200 shadow rounded p-3 sm:p-4 flex gap-5 items-center"
    :class="{
      'border-green-500 border-2': seleccionada,
    }"
    @click="setearTiendaPreSeleccionadaModalDespacho(tienda)"
  >
    <img
      class="rounded-full sm:w-28 sm:h-28 w-24 h-24"
      :src="tienda.imagenes[0].fullUrl"
      :alt="'Foto tienda ' + tienda.nombre"
    />
    <div class="flex flex-col text-left justify-between">
      <p class="text-xs leading-4 font-medium mb-1.5">
        <span
          class="rounded-full px-2 py-0.5"
          :class="{
            'bg-lime-100 text-green-500': tienda.estado === 'Abierta',
            'bg-red-50 text-red-500': tienda.estado === 'Cerrada',
          }"
          >● {{ tienda.estado }}</span
        >
      </p>
      <p class="text-base leading-7 font-semibold mb-1 sm:mb-2">
        Tienda {{ tienda.nombre }}
      </p>
      <p class="text-sm sm:text-xs leading-4 font-semibold mb-1">
        Dirección: <span class="font-normal">{{ tienda.direccion }}</span>
      </p>
      <p class="text-xs leading-4 font-semibold hidden sm:block">
        Horario: <span class="font-normal">{{ tienda.info.horarioText }}</span>
      </p>
    </div>
  </button>
</template>

<script setup lang="ts">
  import { computed } from "@vue/runtime-core";
  import type { Tienda } from "@lib/interfaces";
  import {
    setearTiendaPreSeleccionadaModalDespacho,
    datosModalDespacho,
  } from "@stores/despacho";
  import { useStore } from "@nanostores/vue";

  interface Props {
    tienda: Tienda;
  }
  const props = defineProps<Props>();

  const $datosModalDespacho = useStore(datosModalDespacho);

  const seleccionada = computed(() => {
    return (
      props.tienda.id === $datosModalDespacho.value.tiendaPreSeleccionada?.id
    );
  });
</script>
