<template>
  <div class="w-100 flex flex-col items-center text-center">
    <h3 class="font-semibold">
      Ingresa el código enviado a tu correo electrónico
    </h3>
    <p class="p-3">
      Ingresa el código que te enviamos a
      <span class="text-green-500">{{ $emailRutLogin.emailMostrar }}</span> para
      continuar.
    </p>

    <!-- Contenedor -->
    <div class="relative">
      <div
        class="flex h-20 w-72 items-center rounded-lg border-2 border-gray-500 sm:w-96"
        :class="{
          'border-green-500': codigoValido,
          'border-red-500': errorCodigo,
          'opacity-20': loading,
        }"
      >
        <!-- Inputs -->
        <div class="flex h-16 w-96 items-center justify-center sm:px-6">
          <input
            v-for="(_, index) in inputs"
            v-model="inputs[index]"
            class="mx-1 h-10 w-9 border-x-0 border-b-2 border-t-0 border-gray-300 p-0 text-center text-3xl caret-transparent focus:border-green-500 focus:ring-0 sm:mx-3"
            :key="index"
            autocomplete="off"
            type="tel"
            :disabled="Boolean(disabled)"
            :id="index.toString()"
            ref="inputRefs"
            @keydown="keyDown"
            @paste="pegar"
            :name="`codigo-${index}`"
          />
        </div>
      </div>
      <div
        class="bg-gray-50 h-20 opacity-30 cursor-not-allowed animate-pulse absolute inset-0 rounded-lg sm:w-96"
        v-if="loading"
      ></div>
    </div>
    <div class="w-72 text-xs sm:w-96" v-if="errorCodigo">
      <p class="text-right text-red-500">{{ mensajeError }}</p>
    </div>
    <!-- Countdown y Acciones Usuario -->
    <p class="p-6 font-medium text-red-500">{{ timerString }}</p>
    <p>
      ¿No ha recibido el código?
      <span
        @click="reenviarCodigo"
        class="cursor-pointer font-medium text-green-500 underline underline-offset-4"
        >Reenviar</span
      >
    </p>
    <p class="text-xs mt-2" v-if="$emailRutLogin.esRut">
      Si no recuerdas tu correo electrónico, por favor comunícate con nuestro
      <a
        aria-label="Contáctanos por WhatsApp!"
        href="https://wa.me/56966540811"
        target="_blank"
        class="text-green-500 underline underline-offset-2 hover:text-green-700 transition-colors"
        >servicio al cliente</a
      >.
    </p>
    <!-- <p class="p-6 font-medium text-green-500 underline underline-offset-4">
      Cambiar método de envío
    </p> -->
  </div>
</template>
<script setup lang="ts">
  import { ref, onMounted, computed, onUnmounted } from "@vue/runtime-core";
  import { enviarCodigoEmail, loginCodigoEmail } from "@api/client/users";
  import { useStore } from "@nanostores/vue";
  import { emailRutLogin, setearUsuario } from "@stores/user";
  import {
    cerrarModalDespachoLogin,
    agregarProductoModalDespachoLogin,
    direccionActiva,
    tipoPedidoActivoId,
    setearDireccionEncontrada,
    datosModalDespacho,
    setearComponenteActivoModalDespachoLogin,
    verificarDireccionNoTienda,
  } from "@stores/despacho";
  import { login, loadUser } from "@lib/eventos";
  import type { Direccion } from "@lib/interfaces";

  const $emailRutLogin = useStore(emailRutLogin);
  const $direccionActiva = useStore(direccionActiva);
  const $tipoPedidoActivoId = useStore(tipoPedidoActivoId);
  const $datosModalDespacho = useStore(datosModalDespacho);
  const inputs = ref<String[]>(["", "", "", "", "", ""]);
  const disabled = ref<Boolean>(false);
  const inputRefs = ref<HTMLInputElement[]>([]);
  const codigoValido = ref<Boolean>(false);
  const errorCodigo = ref<Boolean>(false);
  const interval = ref<number | undefined>(undefined);
  const segundosRestantes = ref<number>(300);
  const timerString = ref<string>("05:00");
  const intentosRestantes = ref<number>(3);
  const mensajeError = ref<string>("Error");
  const loading = ref<boolean>(false);

  async function validarCodigoLogin() {
    if (segundosRestantes.value === 0) {
      mensajeError.value = "El código ha expirado, genere un nuevo código.";
      errorCodigo.value = true;
      return;
    }
    loading.value = true;
    verificarDireccionNoTienda();
    let direccion: Direccion | undefined = $direccionActiva.value;
    if ($tipoPedidoActivoId.value === 3) {
      direccion = undefined;
    }
    const { user, direccionEncontrada, estado, numeroIntentos } =
      await loginCodigoEmail(
        codigo.value,
        $emailRutLogin.value.emailRut,
        direccion,
        $emailRutLogin.value.esRut,
      );
    if (estado === "error") {
      errorCodigo.value = true;
      if (numeroIntentos) {
        intentosRestantes.value -= 1;
        if (intentosRestantes.value === 0) {
          disabled.value = true;
          mensajeError.value =
            "Ha superado el número de intentos, genere un nuevo código.";
          errorCodigo.value = true;
          clearInterval(interval.value);
          return (loading.value = false);
        }
        const pluralS = intentosRestantes.value > 1 ? "s" : "";
        const pluralN = intentosRestantes.value > 1 ? "n" : "";
        mensajeError.value = `Código incorrecto, le queda${pluralN} ${intentosRestantes.value} intento${pluralS}.`;
      }
      limpiarCodigo();
      return (loading.value = false);
    }
    errorCodigo.value = false;
    codigoValido.value = true;
    if (direccionEncontrada && $tipoPedidoActivoId.value !== 3) {
      setearDireccionEncontrada(direccionEncontrada);
    }
    if (user) {
      setearUsuario(user);
      login("email");
      loadUser(user);
    }
    agregarProductoModalDespachoLogin();
    loading.value = false;
    if ($datosModalDespacho.value.membresia) {
      return setearComponenteActivoModalDespachoLogin("PagoMembresia");
    }
    cerrarModalDespachoLogin();
  }

  const reenviarCodigo = async () => {
    const { estado } = await enviarCodigoEmail(
      $emailRutLogin.value.emailRut,
      $emailRutLogin.value.esRut,
    );
    if (estado === "error") {
      return;
    }

    segundosRestantes.value = 300;
    intentosRestantes.value = 3;
    errorCodigo.value = false;
    disabled.value = false;
    clearInterval(interval.value);
    interval.value = setInterval(() => {
      timerString.value = tiempoRestante();
    }, 1000);
    limpiarCodigo();
    return;
  };

  onMounted(() => {
    inputRefs.value[0].focus();
    interval.value = setInterval(() => {
      timerString.value = tiempoRestante();
    }, 1000);
  });

  onUnmounted(() => {
    clearInterval(interval.value);
  });

  const codigoCompleto = computed<string | false>(() => {
    if (
      inputs.value.some((input) => {
        return input === "" || input.length > 1;
      })
    ) {
      return false;
    }
    return inputs.value.join("");
  });

  const codigo = computed<string>(() => {
    return inputs.value.join("");
  });

  const keyDown = (e: KeyboardEvent) => {
    if (e.key === "Backspace") return retroceder(e);
    if (!Number.isNaN(+e.key) && e.key !== " ") return avanzar(e);
    return teclaEspecial(e);
  };

  const teclaEspecial = (e: KeyboardEvent) => {
    if (e.key != "Meta" && e.key != "Control" && e.key != "v") {
      e.preventDefault();
    }
  };

  const avanzar = (e: KeyboardEvent) => {
    if (disabled.value) {
      return;
    }
    let target = e.target as HTMLElement;
    let target_id = +target.id;
    inputs.value[target_id] = e.key;
    if (target_id == inputs.value.length - 1) {
      inputRefs.value[inputs.value.length - 1].blur();
    } else {
      inputRefs.value[target_id + 1].focus();
    }
    if (codigoCompleto.value) {
      validarCodigoLogin();
    }

    e.preventDefault();
  };

  const retroceder = (e: KeyboardEvent) => {
    if (disabled.value) {
      return;
    }
    let target = e.target as HTMLElement;
    let target_id = +target.id;
    inputs.value[target_id] = "";
    if (target_id == 0) {
      inputRefs.value[0].focus();
    } else {
      inputRefs.value[target_id - 1].focus();
    }
    e.preventDefault();
  };

  const pegar = (e: ClipboardEvent) => {
    if (disabled.value) {
      return;
    }

    let data = e.clipboardData?.getData("text");

    inputs.value.forEach((input, index) => {
      if (data && index < data.length && !Number.isNaN(+data[index])) {
        inputs.value[index] = data[index];
      }
    });
    e.preventDefault();
    if (codigoCompleto.value) validarCodigoLogin();
  };

  const tiempoRestante = () => {
    if (segundosRestantes.value === 0) {
      clearInterval(interval.value);
      return "00:00";
    }

    const minutos = Math.floor(segundosRestantes.value / 60);
    const segundos = segundosRestantes.value % 60;
    segundosRestantes.value--;
    return `${minutos < 10 ? "0" + minutos : minutos}:${
      segundos < 10 ? "0" + segundos : segundos
    }`;
  };

  const limpiarCodigo = () => {
    inputs.value = ["", "", "", "", "", ""];
    inputRefs.value[0].focus();
  };
</script>
