<template>
  <div class="flex flex-col items-start sm:items-center sm:flex-row">
    <p
      class="mr-3 min-w-max text-sm sm:text-base font-normal leading-6 sm:inline-block"
    >
      Entregar en:
    </p>
    <div class="w-full flex items-center">
      <Listbox
        v-if="
          $user && ($user.direcciones.length || $datosModalDespacho.direccion)
        "
        class="relative grow sm:w-full sm:grow-0"
        v-model="direccionSeleccionada"
        as="div"
      >
        <ListboxButton
          class="relative py-2 w-full rounded-lg border px-3 pr-5 text-left text-xs"
          v-slot="{ open }"
          >{{
            direccionSeleccionada
              ? direccionSeleccionada.direccion
              : "Selecciona dirección"
          }}
          <component
            class="absolute right-2 top-2.5 h-4 w-4"
            :is="open ? ChevronUpIcon : ChevronDownIcon"
          />
        </ListboxButton>
        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <ListboxOption
              class="relative block cursor-pointer truncate px-2 py-1 pr-10 text-sm md:hover:bg-gray-100"
              v-for="direccion in $user.direcciones"
              :key="direccion.id"
              :value="direccion"
            >
              {{ direccion.direccion }}
              <button
                @click.stop="editarDireccion(direccion, 'selectorDirecciones')"
                class="absolute right-4 top-1.5 h-4 w-4 md:hover:text-green-500"
              >
                <PencilSquareIcon />
              </button>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </Listbox>
      <input
        v-else-if="$datosModalDespacho.direccion"
        disabled
        class="relative h-9 w-full rounded-lg border px-3 pr-5 text-left text-xs"
        :value="direccionTexto"
        name="direccion-seleccionada"
      />
      <div class="relative w-full cursor-not-allowed" v-else>
        <input
          disabled
          class="relative h-9 w-full rounded-lg border px-3 pr-5 text-left text-xs"
          placeholder="Agregar dirección"
          name="agregar-direccion"
        />
        <ChevronDownIcon
          class="absolute right-2 top-2.5 h-4 w-4 text-gray-400"
        />
      </div>

      <Button
        class="ml-3"
        type="secondary"
        rounded
        size="xs"
        @click="
          $user || !$datosModalDespacho.direccion
            ? setearComponenteActivoModalDespachoLogin('CrearDireccion')
            : setearComponenteActivoModalDespachoLogin('EditarDireccion')
        "
      >
        <component
          class="h-4 w-4 text-white"
          :is="
            $user || !$datosModalDespacho.direccion
              ? PlusIcon
              : PencilSquareIcon
          "
        />
      </Button>
    </div>
  </div>
</template>
<script setup lang="ts">
  import {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
  } from "@headlessui/vue";
  import {
    ChevronDownIcon,
    ChevronUpIcon,
    PencilSquareIcon,
    PlusIcon,
  } from "@heroicons/vue/24/outline";
  import { useStore } from "@nanostores/vue";
  import {
    editarDireccion,
    datosModalDespacho,
    setearComponenteActivoModalDespachoLogin,
    setearDireccionModalDespacho,
    seleccionarPrimeraVentanaDisponibleModalDespacho,
  } from "@stores/despacho";
  import { user } from "@stores/user";
  import { ref, watch, onMounted, computed } from "@vue/runtime-core";
  import Button from "@ui/Button.vue";

  const $user = useStore(user);

  const $datosModalDespacho = useStore(datosModalDespacho);

  const direccionSeleccionada = ref($datosModalDespacho.value.direccion);

  watch(direccionSeleccionada, async (direccion) => {
    if (!direccion) return;
    setearDireccionModalDespacho(direccion);
    seleccionarPrimeraVentanaDisponibleModalDespacho();
  });

  const direccionTexto = computed(() => {
    if (!$datosModalDespacho.value.direccion) return "";
    return (
      $datosModalDespacho.value.direccion.calle +
      " " +
      $datosModalDespacho.value.direccion.numero +
      ($datosModalDespacho.value.direccion.depto
        ? " Depto/Casa/Oficina " + $datosModalDespacho.value.direccion.depto
        : "") +
      ", " +
      $datosModalDespacho.value.direccion.comuna
    );
  });

  onMounted(async () => {
    if (!$datosModalDespacho.value.ventana) {
      seleccionarPrimeraVentanaDisponibleModalDespacho();
    }
  });
</script>
