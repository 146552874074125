<template>
  <div class="w-full">
    <!-- SELECTOR DE DIRECCION -->
    <div class="justify-center sm:flex sm:gap-x-3">
      <Combobox
        class="relative w-full"
        :class="{
          'sm:w-8/12 mb-4': direccion.calle,
        }"
        v-model="prediccionSeleccionada"
        as="div"
      >
        <div class="relative w-full">
          <CheckCircleIcon
            v-if="direccion.calle"
            class="absolute right-2 top-3 h-5 w-5 text-green-500"
          />
          <ComboboxInput
            class="w-full rounded-md border-gray-200 text-base focus:border-green-500 focus:ring-green-500 focus-visible:border-green-500 focus-visible:ring-green-500"
            :class="{
              'border-green-500': direccion.calle,
            }"
            placeholder="Ingresa tu dirección"
            @change="buscarDireccionDebounce($event.target.value)"
            :displayValue="() => direccion.calle"
          />
        </div>
        <ComboboxOptions
          v-if="loadingPredictions || predicciones.length > 0"
          class="absolute z-10 mt-1 max-h-44 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ComboboxOption
            v-if="!loadingPredictions"
            class="block cursor-pointer truncate px-2 py-1 text-sm md:hover:bg-gray-100"
            v-for="prediccion in predicciones"
            :key="prediccion.place_id"
            :value="prediccion"
          >
            {{ prediccion.description }}
          </ComboboxOption>
          <ComboboxOption
            v-if="loadingPredictions"
            class="flex h-40 items-center justify-center"
          >
            <Loader />
          </ComboboxOption>
        </ComboboxOptions>
      </Combobox>
      <!-- DESGLOSE DIRECCION -->
      <div
        v-if="direccion.calle"
        class="mt-4 grid grid-cols-2 gap-x-3 gap-y-4 sm:m-0"
      >
        <div class="relative col-span-1">
          <input
            name="numero"
            class="w-full rounded-md border-gray-200 text-base focus:border-green-500 focus:ring-green-500 focus-visible:border-green-500 focus-visible:ring-green-500"
            type="text"
            :class="{ 'border-green-500': numeroOk }"
            v-model="direccion.numero"
            @keyup="cambioNumeroDebounce"
            placeholder="Numero"
          />
          <CheckCircleIcon
            v-if="numeroOk"
            class="absolute right-2 top-3 h-5 w-5 text-green-500"
          />
          <XCircleIcon
            v-else-if="numeroOk === false"
            class="absolute right-2 top-3 h-5 w-5 text-red-500"
          />
        </div>
        <input
          name="depto"
          class="col-span-1 mb-4 rounded-md border-gray-200 text-base focus:border-green-500 focus:ring-green-500 focus-visible:border-green-500 focus-visible:ring-green-500"
          type="text"
          v-model="direccion.depto"
          placeholder="Depto/casa/oficina"
        />
      </div>
    </div>

    <div v-if="direccion.calle" class="mb-5 flex justify-end">
      <p class="text-sm">
        <span class="mr-0.5 font-medium">Región:</span
        >{{ direccion.region.replace("Región", "") }}
        <span class="mr-0.5 text-gray-500">|</span>
        <span class="mr-0.5 font-medium">Comuna:</span>{{ direccion.comuna }}
      </p>
    </div>
    <!-- MAPA -->
    <div
      class="mb-4 h-48 sm:h-52"
      :class="{ block: direccion.calle, hidden: !direccion.calle }"
      id="map"
    ></div>
    <!-- BOTON CONTINUAR -->
    <MembresiaSeleccionada
      class="mt-2"
      :class="{
        'mb-6': direccion.calle,
      }"
    />
    <div v-if="direccion.calle">
      <Button
        class="w-full"
        type="secondary"
        :disabled="!puedeContinuar"
        @click="continuar"
      >
        {{ textoBotonContinuar }}
      </Button>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { ref, onMounted, watch, computed } from "@vue/runtime-core";
  import {
    createMap,
    getPlacePredictions,
    getPlaceDetails,
    crearMarcador,
    dentroDelPoligono,
  } from "@plugins/googleMaps";
  import {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
  } from "@headlessui/vue";
  import {
    setearZonasDespachoDisponiblesModalDespacho,
    setearDireccionModalDespacho,
    direccionEditar,
    limpiarDireccionEditar,
    setearComponenteActivoModalDespachoLogin,
    setearTipoPedidoModalDespacho,
    seleccionarPrimeraVentanaDisponibleModalDespacho,
    clickDesdeMiCuenta,
    cerrarModalDespachoLogin,
    guardarDatosModalDespacho,
    datosModalDespacho,
    setearTabModalDespacho,
  } from "@stores/despacho";
  import { configAnilloReparto } from "@stores/app";
  import { user } from "@stores/user";
  import { actualizarDireccionEditada, agregarDireccion } from "@stores/user";
  import debounce from "lodash/debounce";
  import type { Direccion } from "@lib/interfaces";
  import Loader from "@components/Loader.vue";
  import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/20/solid";
  import Button from "@components/ui/Button.vue";
  import { useStore } from "@nanostores/vue";
  import { editarDireccion, crearDireccion } from "@api/client/direcciones";
  import MembresiaSeleccionada from "@components/membresia/MembresiaSeleccionada.vue";

  interface Props {
    mostrarRetiroEnTienda?: boolean;
  }
  withDefaults(defineProps<Props>(), {
    mostrarRetiroEnTienda: false,
  });

  const emit = defineEmits(["direccionSeleccionada"]);

  const predicciones = ref<google.maps.places.AutocompletePrediction[]>([]);
  const prediccionSeleccionada = ref<
    google.maps.places.PlaceResult | undefined
  >(undefined);
  const marcador = ref<google.maps.Marker | undefined>(undefined);
  const latLng = ref<google.maps.LatLng | undefined>(undefined);
  const googleMap = ref<google.maps.Map | undefined>(undefined);

  const $user = useStore(user);
  const $direccionEditar = useStore(direccionEditar);
  const $clickDesdeMiCuenta = useStore(clickDesdeMiCuenta);
  const $configAnilloReparto = useStore(configAnilloReparto);
  const $datosModalDespacho = useStore(datosModalDespacho);

  const direccionBase: Direccion = {
    calle: "",
    numero: "",
    depto: "",
    region: "",
    comuna: "",
    coordenadas: "",
    observaciones: "",
  };

  const loadingPredictions = ref(false);
  const direccion = ref<Direccion>(
    $direccionEditar.value ? { ...$direccionEditar.value } : direccionBase,
  );
  const numeroOk = ref<boolean | undefined>(undefined);

  watch(prediccionSeleccionada, async (prediccionNueva, prediccionAntigua) => {
    if (!prediccionNueva || prediccionNueva === prediccionAntigua) return;
    seleccionarPrediccion(prediccionNueva);
  });

  watch($direccionEditar, async (direccionNueva, direccionAntigua) => {
    if (!direccionNueva) return;
    direccion.value = direccionNueva;
    desglosarDireccion(direccionNueva);
  });

  const seleccionarPrediccion = async (
    prediccion: google.maps.places.PlaceResult,
  ) => {
    if (!prediccion.place_id) return;
    const place = await getPlaceDetails(prediccion.place_id);
    if (!place) return;
    latLng.value = place.geometry?.location;
    if (!latLng.value) return;
    construirDireccion(place);
    definirMarcador();
    emit("direccionSeleccionada");
  };

  const buscarDireccionDebounce = debounce(
    (direccion: string) => buscarDireccion(direccion),
    500,
  );

  const buscarDireccion = (direccion: string) => {
    return new Promise<google.maps.places.AutocompletePrediction[]>(
      async (resolve) => {
        loadingPredictions.value = true;
        predicciones.value = [];
        if (!direccion) return (loadingPredictions.value = false);
        if (direccion.length < 3) return (loadingPredictions.value = false);
        let predictions = await getPlacePredictions(direccion + "");
        if (!predictions) return (loadingPredictions.value = false);
        predicciones.value = predictions;
        loadingPredictions.value = false;
        resolve(predictions);
      },
    );
  };

  const construirDireccion = (place: google.maps.places.PlaceResult) => {
    if (
      !place.geometry ||
      !place.geometry.location ||
      !place.address_components
    )
      return;

    direccion.value.calle = "";
    direccion.value.region = "";
    direccion.value.comuna = "";
    direccion.value.coordenadas = "";

    let posibleComuna = "";
    let posibleComunaLocality = "";
    place.address_components.forEach((component) => {
      if (component.types.includes("route")) {
        direccion.value.calle = component.long_name;
      }
      if (component.types.includes("street_number")) {
        direccion.value.numero = component.long_name;
        numeroOk.value = true;
      }
      if (component.types.includes("administrative_area_level_1")) {
        direccion.value.region = component.long_name;
      }
      if (component.types.includes("administrative_area_level_3")) {
        posibleComuna = component.long_name;
      }
      if (component.types.includes("locality")) {
        posibleComunaLocality = component.long_name;
      }
    });
    direccion.value.comuna =
      posibleComuna == "Santiago" ? posibleComunaLocality : posibleComuna;

    direccion.value.coordenadas = `${place.geometry.location.lat()},${place.geometry.location.lng()}`;
  };

  const definirMarcador = () => {
    if (!latLng.value) return;
    if (!googleMap.value) return;
    googleMap.value.setCenter(latLng.value);
    googleMap.value.setZoom(16);
    if (marcador.value) return marcador.value.setPosition(latLng.value);
    marcador.value = crearMarcador(latLng.value, googleMap.value, true, true);
    marcador.value.addListener("dragend", moverMarcador);
    moverMarcador();
  };

  const moverMarcador = () => {
    if (!marcador.value) return;
    let latLngNuevo = marcador.value.getPosition();
    if (!latLngNuevo) return;
    latLng.value = latLngNuevo;
    direccion.value.coordenadas = `${latLng.value.lat()}, ${latLng.value.lng()}`;
  };

  const cambioNumeroDebounce = debounce(() => cambioNumero(), 500);

  const cambioNumero = async () => {
    if (!direccion.value.numero) return (numeroOk.value = false);
    if (direccion.value.numero.length < 1) return (numeroOk.value = false);
    if (isNaN(parseInt(direccion.value.numero)))
      return (numeroOk.value = false);
    numeroOk.value = false;
    await desglosarDireccion(direccion.value);
    if (!numeroOk.value) return (numeroOk.value = false);
  };

  const desglosarDireccion = async (direccion: Direccion) => {
    let direccionBuscar = `${direccion.calle} ${direccion.numero}, ${direccion.comuna}`;
    let predicciones = await buscarDireccion(direccionBuscar);
    if (!predicciones) return (numeroOk.value = false);
    seleccionarPrediccion(predicciones[0]);
  };

  const continuar = async () => {
    if (direccion.value.id) return guardarCambios();
    if (!puedeContinuar || !latLng.value) return;
    if ($user.value) {
      const resultadoAnillo = await calcularAnilloReparto(
        direccion.value,
        $configAnilloReparto.value,
      );
      if (resultadoAnillo !== undefined) {
        direccion.value.en_anillo_reparto = resultadoAnillo;
        direccion.value.calculado_anillo = true;
      }
      const responseDireccion = await crearDireccion(direccion.value);
      if (responseDireccion.estado === "error") {
        return alert("Error al guardar la direccion");
      }
      direccion.value = responseDireccion.direccion;
      agregarDireccion(responseDireccion.direccion);
    }
    if (!$clickDesdeMiCuenta.value) {
      setearDireccionModalDespacho(direccion.value);
      seleccionarPrimeraVentanaDisponibleModalDespacho();
      if (!$user.value && $datosModalDespacho.value.ventana) {
        guardarDatosModalDespacho();
        return setearComponenteActivoModalDespachoLogin("RegistroLogin");
      } else if (!$user.value) {
        setearTabModalDespacho("retiro");
      }
      setearComponenteActivoModalDespachoLogin("SeleccionarDespacho");
    } else {
      cerrarModalDespachoLogin();
    }
  };

  const calcularAnilloReparto = async (
    direccion: Direccion,
    coordenadasAnillo: ReadonlyArray<Readonly<number[]>>,
  ) => {
    if (!direccion.coordenadas) return;
    const respuesta = await dentroDelPoligono(
      direccion.coordenadas,
      coordenadasAnillo,
    );
    return respuesta;
  };

  const guardarCambios = async () => {
    if (!direccion.value.id) return;
    if (!latLng.value) return;
    const resultadoAnillo = await calcularAnilloReparto(
      direccion.value,
      $configAnilloReparto.value,
    );
    if (resultadoAnillo !== undefined) {
      direccion.value.en_anillo_reparto = resultadoAnillo;
      direccion.value.calculado_anillo = true;
    }
    const respuesta = await editarDireccion(direccion.value);
    if (respuesta.estado === "error") {
      return alert("Error al guardar la direccion");
    }
    setearZonasDespachoDisponiblesModalDespacho(latLng.value);
    actualizarDireccionEditada(respuesta.direccion);
    setearDireccionModalDespacho(respuesta.direccion);
    limpiarDireccionEditar();
    setearTipoPedidoModalDespacho(undefined);
    if (!$clickDesdeMiCuenta.value) {
      setearComponenteActivoModalDespachoLogin("SeleccionarDespacho");
    } else {
      cerrarModalDespachoLogin();
    }
  };

  const textoBotonContinuar = computed(() => {
    if (direccion.value.id) return "Guardar cambios";
    if (!$user.value) return "Continuar con el registro";
    return "Continuar";
  });

  onMounted(async () => {
    const mapDiv = document.getElementById("map");
    if (!mapDiv) {
      console.error("No se encontró el mapa");
      return;
    }
    googleMap.value = await createMap(mapDiv);
    if (direccion.value.id) {
      desglosarDireccion(direccion.value);
      prediccionSeleccionada.value = {};
    }
  });

  const puedeContinuar = computed(() => {
    return (
      direccion.value.calle &&
      direccion.value.numero &&
      direccion.value.region &&
      direccion.value.comuna &&
      direccion.value.coordenadas &&
      numeroOk.value &&
      latLng.value
    );
  });
</script>
