<template>
  <div>
    <h1 class="text-xl leading-7 font-semibold mb-3 text-center">
      ¡Bienvenid@ a FREST!
    </h1>
    <h2
      class="text-lg leading-7 font-normal text-gray-700 mb-6 text-center md:mx-36"
      v-if="$datosModalDespacho.tabRegistroLogin == 'login'"
    >
      ¡We love you <span class="text-red-500">berry</span> much! Inicia sesión y
      prepárate para llenar de color tu refri
    </h2>
    <h2
      class="text-lg leading-7 font-normal text-gray-700 mb-6 text-center md:mx-8"
      v-else
    >
      Enviamos a tu correo el cupón para que comiences tu viaje hacia una forma
      de comer más rica y natural.
    </h2>
    <p v-if="errorRegistro" class="text-red-500 text-center -mt-4 mb-4">
      Tienes que registrarte antes de poder iniciar sesión con Google
    </p>
    <TabsRegistroLogin class="mb-6" />
    <div v-if="$datosModalDespacho.tabRegistroLogin == 'registro'">
      <Registro />
      <ReferidoPor class="mt-5" />
    </div>
    <div v-if="$datosModalDespacho.tabRegistroLogin == 'login'">
      <InicioSesion />
      <ReferidoPor />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useStore } from "@nanostores/vue";
  import { datosModalDespacho } from "@stores/despacho";
  import TabsRegistroLogin from "./TabsRegistroLogin.vue";
  import InicioSesion from "./InicioSesion.vue";
  import Registro from "./Registro.vue";
  import ReferidoPor from "../ReferidoPor.vue";
  import { onMounted, ref } from "@vue/runtime-core";
  import { deleteCookie, getCookie } from "@lib/cookies";

  const $datosModalDespacho = useStore(datosModalDespacho);
  const errorRegistro = ref(false);

  onMounted(() => {
    if (getCookie("errorRegistro")) {
      errorRegistro.value = true;
    }
    deleteCookie("errorRegistro");
  });
</script>
