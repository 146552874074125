<template>
  <div>
    <div class="w-full h-52 hidden sm:block" id="map"></div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, watch } from "@vue/runtime-core";
  import { createMap, crearMarcador } from "@lib/plugins/googleMaps";
  import {
    datosModalDespacho,
    tiendasConRetiro,
    setearTiendaModalDespacho,
  } from "@stores/despacho";
  import { useStore } from "@nanostores/vue";
  import type { Tienda } from "@lib/interfaces";

  const googleMap = ref<google.maps.Map>();
  const $datosModalDespacho = useStore(datosModalDespacho);
  const $tiendasConRetiro = useStore(tiendasConRetiro);
  const markers = ref<google.maps.Marker[]>([]);

  onMounted(async () => {
    const mapDiv = document.getElementById("map");
    if (!mapDiv) {
      console.error("No se encontró el mapa");
      return;
    }
    googleMap.value = await createMap(mapDiv);
    $tiendasConRetiro.value.forEach(crearMarcadorTienda);
  });

  const crearMarcadorTienda = (tienda: Tienda) => {
    if (!googleMap.value) return;
    const coordenadas = {
      lat: +tienda.coordenadas.split(",")[0],
      lng: +tienda.coordenadas.split(",")[1],
    };

    const marker = crearMarcador(
      coordenadas,
      googleMap.value,
      false,
      true,
      "/images/icons/banana-marcador.svg",
    );
    marker.addListener("click", () => {
      if (!googleMap.value) return;
      googleMap.value.setCenter(marker.getPosition() as google.maps.LatLng);
      googleMap.value.setZoom(15);
      setearTiendaModalDespacho(tienda);
    });
    markers.value.push(marker);
  };

  watch(
    () => $datosModalDespacho.value.tiendaSeleccionada,
    (tienda) => {
      if (!tienda) return;
      const coordenadas = {
        lat: +tienda.coordenadas.split(",")[0],
        lng: +tienda.coordenadas.split(",")[1],
      };
      googleMap.value?.setCenter(coordenadas);
      googleMap.value?.setZoom(15);
    },
  );
</script>
