<template>
  <h2
    class="text-lg leading-7 font-normal text-gray-700 mb-6 text-center md:mx-8"
    v-if="$referidor"
  >
    Has sido referido por
    <span class="font-medium text-gray-900">{{ $referidor.nombre }}</span>
  </h2>
</template>
<script setup lang="ts">
  import { useStore } from "@nanostores/vue";
  import { referidor } from "@stores/app";
  const $referidor = useStore(referidor);
</script>
