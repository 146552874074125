<template>
  <div class="relative">
    <div
      :id="'carrusel-ventanas-' + contexto"
      class="flex w-full items-center"
      :class="{
        'opacity-20 pointer-events-none': loader || !tieneDespacho,
      }"
    >
      <Flicking
        class="mx-1 pb-10"
        :options="options"
        :plugins="pluginsCarruselVentanasDespacho"
        ref="carruselVentanasDespacho"
      >
        <div
          v-for="(tiposPedido, fecha) in ventanasAgrupadas"
          :key="fecha"
          class="flex flex-col items-center mx-4 sm:mx-0 sm:px-5 gap-5 max-w-full lg:first:pl-0 lg:border-r lg:border-gray-100 lg:last:border-r-0"
        >
          <p class="md:text-lg text-base leading-6 font-semibold">
            {{ fechaCarruselVentanas(fecha) }}
          </p>
          <div class="flex gap-2">
            <div
              class="flex flex-col items-center"
              v-for="ventanasTipoPedido in tiposPedido"
            >
              <div class="flex justify-between items-center w-full">
                <div
                  class="w-full flex items-center justify-center bg-lime-100 py-1 px-3 mb-2 rounded"
                >
                  <p class="text-green-500 text-base leading-6 font-medium">
                    {{ primerPrecioValorVentanas(ventanasTipoPedido) }}
                  </p>
                </div>
              </div>
              <div class="flex flex-col gap-2 grow">
                <BotonVentana
                  v-for="ventana in ventanasTipoPedido.ventanas"
                  :class="{
                    'h-8': ventanasTipoPedido.tipoPedido.id == 2,
                    'grow w-28': ventanasTipoPedido.tipoPedido.id == 1,
                  }"
                  :ventana="ventana"
                  :contexto="contexto"
                  :tipoPedido="ventanasTipoPedido.tipoPedido"
                />
              </div>
            </div>
          </div>
        </div>
        <template #viewport>
          <Paginador
            class="-bottom-2"
            :carrusel="carruselVentanasDespacho"
            :nombre-paginador="nombrePaginadorCarruselVentanasDespacho"
            :background="false"
          />
        </template>
      </Flicking>
    </div>
    <div
      v-if="!tieneDespacho"
      class="absolute top-1/3 bg-yellow-50 text-yellow-700 text-sm leading-5 font-medium border-l-4 border-l-yellow-400 flex px-4 py-12"
    >
      <div>
        <ExclamationTriangleIcon
          class="w-5 h-5 text-yellow-400 mr-2 inline-block"
        />
      </div>
      <p class="inline-block">
        Si quieres despacho a domicilio, debes ingresar una dirección que se
        encuentre dentro de nuestro rango de cobertura de despachos. Revisa el
        rango
        <a
          class="md:hover:text-yellow-800 transition-colors"
          href="/zonas-de-despacho"
          target="_blank"
          >aquí</a
        >.
      </p>
    </div>
    <Loader class="absolute top-1/2 right-1/2" v-if="loader" />
  </div>
</template>

<script setup lang="ts">
  import { ref, computed, onMounted, nextTick, watch } from "@vue/runtime-core";
  import Flicking from "@egjs/vue3-flicking";
  import "@egjs/vue3-flicking/dist/flicking.css";
  import {
    ventanasAgrupadasModalDespacho,
    ventanasAgrupadasCheckout,
    tiposPedidoDisponiblesModalDespacho,
    tipoPedidoActivoId,
    loaderCarruselVentanas,
    datosModalDespacho,
  } from "@stores/despacho";
  import { useStore } from "@nanostores/vue";
  import BotonVentana from "./BotonVentana.vue";
  import { fechaCarruselVentanas } from "@lib/formatters";
  import { ExclamationTriangleIcon } from "@heroicons/vue/20/solid";
  import Loader from "@components/Loader.vue";
  import { ventanaActiva } from "@stores/despacho";
  import { moneda } from "@lib/formatters";
  import type { TipoPedido, Ventana } from "@lib/interfaces";
  import { user } from "@stores/user";
  import { plugins } from "@lib/bullets";
  import Paginador from "@ui/Paginador.vue";
  interface Props {
    contexto: "modal" | "checkout";
  }
  const props = defineProps<Props>();

  const options = {
    align: window.innerWidth > 1024 ? "prev" : "center",
    resizeDebounce: 120,
    bound: window.innerWidth > 1024 ? true : false,
  };

  const carruselVentanasDespacho = ref<Flicking>();
  const nombrePaginadorCarruselVentanasDespacho =
    "paginador-carrusel-ventanas-despacho";
  const pluginsCarruselVentanasDespacho = plugins(
    nombrePaginadorCarruselVentanasDespacho,
    false,
    false,
    "xs",
  );

  const $ventanaActiva = useStore(ventanaActiva);

  const $tiposPedidoDisponiblesModalDespacho = useStore(
    tiposPedidoDisponiblesModalDespacho,
  );

  const $user = useStore(user);
  const $tipoPedidoActivoId = useStore(tipoPedidoActivoId);
  const $datosModalDespacho = useStore(datosModalDespacho);

  const loader = useStore(loaderCarruselVentanas);
  const primerPrecioValorVentanas = (ventanaAgrupada: {
    ventanas: readonly (Ventana | { horario: string; disponible: boolean })[];
    tipoPedido: TipoPedido;
  }) => {
    //Callbacks si no tienen costo de despacho
    if (
      ventanaAgrupada.ventanas.every((ventana) => !ventana.hasOwnProperty("id"))
    ) {
      if (ventanaAgrupada.tipoPedido.id == 1) return " - ";
      if (ventanaAgrupada.tipoPedido.id == 2) return " - ";
    }

    const primeraVentana = ventanaAgrupada.ventanas[0];

    if (!primeraVentana || !("rangos" in primeraVentana)) return " - ";
    return moneda(+primeraVentana.rangos[0].valor);
  };

  const ventanasAgrupadas = computed(() => {
    if (props.contexto == "modal")
      return useStore(ventanasAgrupadasModalDespacho).value;
    if (props.contexto == "checkout")
      return useStore(ventanasAgrupadasCheckout).value;
  });

  const tieneDespacho = computed(() => {
    if (props.contexto == "checkout") {
      if ($tipoPedidoActivoId.value === 3 && !$user.value) return false;
      if ($user.value && !$user.value.direcciones.length) return false;
      return true;
    }
    return $tiposPedidoDisponiblesModalDespacho.value.some(
      (tipoPedido) => tipoPedido.tiene_despacho == true,
    );
  });

  const ventanaSeleccionada = computed(() => {
    if (props.contexto == "modal") {
      return $datosModalDespacho.value.ventana;
    }
    if (props.contexto == "checkout") {
      return $ventanaActiva.value;
    }
  });

  const centrarCarrusel = () => {
    if (!ventanasAgrupadas.value) return;
    if (!ventanaSeleccionada.value) return;
    let indexVentanaSeleccionada = 0;
    const fechas = Object.keys(ventanasAgrupadas.value || {});
    if (fechas.length === 0) return;
    for (let i = 0; i < fechas.length; i++) {
      if (ventanaSeleccionada.value.fecha === fechas[i]) {
        indexVentanaSeleccionada = i;
        break;
      }
    }
    if (indexVentanaSeleccionada > 0) {
      indexVentanaSeleccionada =
        window.innerWidth < 1024
          ? indexVentanaSeleccionada
          : indexVentanaSeleccionada - 1;
    }
    if (carruselVentanasDespacho.value) {
      carruselVentanasDespacho.value.moveTo(indexVentanaSeleccionada);
    }
  };

  onMounted(() => {
    nextTick(() => {
      centrarCarrusel();
    });
  });

  watch(
    () => ventanaSeleccionada.value,
    () => {
      nextTick(() => {
        centrarCarrusel();
      });
    },
  );
</script>
