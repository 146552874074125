<template>
  <div class="flex justify-between gap-4">
    <button
      class="border border-gray-100 rounded-md p-2 leading-6 font-bold w-1/2 shadow-sm transition-colors text-xs sm:text-base flex justify-center items-center gap-2"
      :class="{
        'bg-green-500 text-white border-green-500':
          tabRegistroLoginModel == 'registro',
      }"
      @click="cambiarTab('registro')"
    >
      Registrate
    </button>
    <button
      class="border border-gray-100 rounded-md p-2 leading-6 font-bold w-1/2 shadow-sm transition-colors text-xs sm:text-base flex justify-center items-center gap-2"
      :class="{
        'bg-green-500 text-white border-green-500':
          tabRegistroLoginModel == 'login',
      }"
      @click="cambiarTab('login')"
    >
      Iniciar sesión
    </button>
  </div>
</template>

<script setup lang="ts">
  import { computed } from "@vue/runtime-core";
  import {
    datosModalDespacho,
    setearComponenteActivoModalDespachoLogin,
  } from "@stores/despacho";
  import { user } from "@stores/user";
  import { useVModel, useStore } from "@nanostores/vue";

  const { tabRegistroLoginModel } = useVModel(datosModalDespacho, [
    "tabRegistroLogin",
  ]);
  const $datosModalDespacho = useStore(datosModalDespacho);
  const $user = useStore(user);

  const cambiarTab = (tab: "login" | "registro") => {
    tabRegistroLoginModel.value = tab;
    if (tab == "registro" && !tieneDespacho.value) {
      return setearComponenteActivoModalDespachoLogin("SeleccionarDespacho");
    }
  };

  const tieneDespacho = computed(() => {
    if (
      $datosModalDespacho.value.tabDespacho === "retiro" &&
      !$datosModalDespacho.value.tiendaSeleccionada
    ) {
      return false;
    }
    if (
      $datosModalDespacho.value.tabDespacho === "despacho" &&
      !hayDireccion.value
    ) {
      return false;
    }
    return true;
  });

  const hayDireccion = computed(() => {
    if ($user.value && $user.value.direcciones.length > 0) {
      return true;
    }
    if ($datosModalDespacho.value.direccion) {
      return true;
    }
    return false;
  });
</script>
