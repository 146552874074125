<template>
  <div class="flex justify-between gap-4">
    <button
      class="border border-gray-100 rounded-md p-2 leading-6 font-bold w-1/2 shadow-sm transition-colors text-xs sm:text-base flex justify-center items-center gap-2"
      :class="{
        'bg-green-500 text-white border-green-500':
          $datosModalDespacho.tabDespacho == 'despacho',
      }"
      @click="cambiarTab('despacho')"
    >
      <DespachoIcon
        class="fill-green-500 transition-colors hidden sm:block"
        :class="{
          'fill-white': $datosModalDespacho.tabDespacho == 'despacho',
        }"
      />
      Despacho a domicilio
    </button>
    <button
      class="border border-gray-100 rounded-md p-2 leading-6 font-bold w-1/2 shadow-sm transition-colors text-xs sm:text-base flex justify-center items-center gap-2 relative"
      :class="{
        'bg-green-500 text-white border-green-500':
          $datosModalDespacho.tabDespacho == 'retiro',
      }"
      @click="cambiarTab('retiro')"
    >
      <RetiroIcon
        class="fill-green-500 transition-colors hidden sm:block"
        :class="{
          'fill-white': $datosModalDespacho.tabDespacho == 'retiro',
        }"
      />
      Retiro en tienda
      <div
        class="w-12 h-3 lg:h-4 absolute rounded-lg bg-lime-100 text-green-500 text-xs leading-4 font-semibold top-0.5 right-1 flex items-center justify-center"
      >
        Gratis
      </div>
    </button>
  </div>
</template>

<script setup lang="ts">
  import { datosModalDespacho, setearTabModalDespacho } from "@stores/despacho";
  import { useStore } from "@nanostores/vue";
  import RetiroIcon from "@components/iconos/RetiroIcon.vue";
  import DespachoIcon from "@components/iconos/DespachoIcon.vue";

  const $datosModalDespacho = useStore(datosModalDespacho);

  const cambiarTab = (tab: "despacho" | "retiro") => {
    if (tab === $datosModalDespacho.value.tabDespacho) return;
    setearTabModalDespacho(tab);
  };
</script>
