<template>
  <div class="flex flex-col items-center gap-2 sm:px-24 sm:pb-4">
    <div class="flex flex-col justify-center align-middle items-center">
      <div class="relative -m-6 -bottom-6 -z-10">
        <picture>
          <source
            srcset="assets/modalDespachoLogin/paltaNotebook.png"
            type="image/png"
          />
          <source
            srcset="assets/modalDespachoLogin/paltaNotebook.webp"
            type="image/webp"
          />

          <img src="" alt="palta con un notebook" class="w-40 object-bottom" />
        </picture>
      </div>
      <picture>
        <source srcset="assets/gifs/email.webp" type="image/webp" />
        <source srcset="assets/gifs/email.gif" type="image/gif" />
        <img src="" alt="gif tutorial" class="z-50" />
      </picture>
    </div>
    <h2 class="text-base font-semibold leading-6">¡FELICITACIONES!</h2>
    <p class="text-sm font-normal leading-5 text-gray-700 text-center">
      <span class="font-bold">Ingresa a tu correo</span> para activar tu cupón y
      aprovechar al máximo esta oferta especial.
    </p>
    <a v-if="urlCorreo" :href="urlCorreo" class="w-full" target="_blank">
      <Button type="secondary" class="w-full" @click="cerrar"
        >Ir a mi correo</Button
      >
    </a>
    <Button type="secondary" class="w-full" v-else @click="cerrar">
      Continuar
    </Button>
  </div>
</template>
<script setup lang="ts">
  import Button from "@components/ui/Button.vue";
  import { obtenerUrlBandejaEntrada } from "@api/client/users";
  import { useStore } from "@nanostores/vue";
  import { user } from "@stores/user";
  import { onMounted, ref } from "@vue/runtime-core";
  import { cerrarModalDespachoLogin } from "@stores/despacho";

  const $user = useStore(user);
  let urlCorreo = ref<string>("");

  const cerrar = () => {
    cerrarModalDespachoLogin();
  };

  onMounted(async () => {
    if (!$user.value) return;
    const correo = $user.value.email;
    const { urlCorreo: urlCorreoResponse } = await obtenerUrlBandejaEntrada(
      correo,
    );
    urlCorreo.value = urlCorreoResponse;
  });
</script>
