<template>
  <TransitionRoot appear :show="$visible" as="template">
    <Dialog as="div" class="relative z-30 w-full">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full justify-center text-center sm:items-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="min-h-full w-full transform bg-white text-left align-middle shadow-xl transition-all sm:max-w-2xl md:rounded-md"
            >
              <button class="absolute right-2 top-1 p-2" @click="closeModal">
                <svg
                  class="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <button
                v-if="$datosModalDespacho.historialComponentes.length"
                class="flex items-center text-base leading-6 font-bold absolute top-4 md:top-6 left-4"
                @click="volverComponenteAnteriorModalDespachoLogin"
              >
                <ArrowLongLeftIcon class="h-5 w-5 mr-3" />
                Volver
              </button>
              <!-- CONTENIDO -->
              <div class="mt-7 p-4 sm:p-5">
                <component
                  v-if="compontenteActivo"
                  :is="compontenteActivo.componente"
                />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script setup lang="ts">
  import SeleccionarDespacho from "@components/despacho/SeleccionarDespacho.vue";
  import EditarDireccion from "@components/despacho/EditarDireccion.vue";
  import CrearDireccion from "@components/despacho/CrearDireccion.vue";
  import CodigoLogin from "./CodigoLogin.vue";
  import BeneficioActivado from "./BeneficioActivado.vue";
  import PagoMembresia from "@components/membresia/PagoMembresia.vue";
  import SeleccionarMembresia from "@components/membresia/SeleccionarMembresia.vue";
  import RegistroLogin from "./RegistroLogin.vue";
  import { useStore } from "@nanostores/vue";
  import {
    modalDespachoLogin,
    cerrarModalDespachoLogin,
    componenteActivoModalDespachoLogin,
    datosModalDespacho,
    volverComponenteAnteriorModalDespachoLogin,
  } from "@stores/despacho";
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
  } from "@headlessui/vue";
  import { computed } from "@vue/runtime-core";
  import Problemas from "./Problemas.vue";
  import { ArrowLongLeftIcon } from "@heroicons/vue/24/outline";

  const $visible = useStore(modalDespachoLogin);
  const $datosModalDespacho = useStore(datosModalDespacho);

  const componentes = [
    { nombre: "SeleccionarDespacho", componente: SeleccionarDespacho },
    { nombre: "EditarDireccion", componente: EditarDireccion },
    { nombre: "CrearDireccion", componente: CrearDireccion },
    { nombre: "CodigoLogin", componente: CodigoLogin },
    { nombre: "BeneficioActivado", componente: BeneficioActivado },
    { nombre: "Problemas", componente: Problemas },
    { nombre: "PagoMembresia", componente: PagoMembresia },
    { nombre: "SeleccionarMembresia", componente: SeleccionarMembresia },
    { nombre: "RegistroLogin", componente: RegistroLogin },
  ];

  const $componenteActivo = useStore(componenteActivoModalDespachoLogin);

  const compontenteActivo = computed(() =>
    componentes.find((c) => c.nombre === $componenteActivo.value),
  );

  const closeModal = () => {
    cerrarModalDespachoLogin();
  };
</script>
