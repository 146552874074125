<template>
  <div>
    <h1
      class="mb-4 flex flex-col items-center text-center text-xl font-semibold leading-7"
    >
      Ingresa dirección y descubre lo que tenemos para ti.
    </h1>
    <div>
      <h2
        class="mb-6 text-center text-base font-normal leading-5 text-gray-700 sm:mx-16"
      >
        <span v-if="$user">
          {{
            $datosModalDespacho.tabDespacho === "despacho"
              ? "¡Tú decides la opción que más te conviene!"
              : "¡Te ayudamos a encontrar la tienda más cercana!"
          }}
        </span>
        <span v-else>
          Si ya tienes cuenta
          <span
            class="text-green-500 underline underline-offset-2 transition-colors hover:text-green-700 cursor-pointer"
            @click="iniciarSesion"
            >inicia sesión</span
          >
        </span>
      </h2>

      <SelectorDirecciones
        v-if="$datosModalDespacho.tabDespacho === 'despacho' && hayDireccion"
        class="mb-6"
      />
      <SelectorDireccionesTiendas
        v-if="
          $datosModalDespacho.tabDespacho === 'retiro' && hayTiendaSeleccionada
        "
        class="mb-6"
      />

      <TabsDespacho class="mb-4" />
      <BannerFrestarDisclosure
        v-if="
          $datosModalDespacho.tabDespacho === 'despacho' &&
          hayDireccion &&
          (!$user || !$user.frestar)
        "
        class="mb-4"
      />
      <MapaTiendasRetiro
        class="mb-6"
        v-if="
          $datosModalDespacho.tabDespacho === 'retiro' && hayTiendaSeleccionada
        "
      />
      <CarruselVentanasDespacho
        v-if="$datosModalDespacho.tabDespacho === 'despacho' && hayDireccion"
        class="mb-6"
        contexto="modal"
      />
      <IngresarDireccion
        v-if="$datosModalDespacho.tabDespacho === 'despacho' && !hayDireccion"
      />
      <CarruselVentanasRetiro
        v-if="
          $datosModalDespacho.tabDespacho === 'retiro' && hayTiendaSeleccionada
        "
        contexto="modal"
        class="mb-6"
      />
      <SelectorTiendas
        v-if="
          $datosModalDespacho.tabDespacho === 'retiro' &&
          !$datosModalDespacho.tiendaSeleccionada
        "
      />
      <Button
        v-if="
          (hayDireccion && $datosModalDespacho.tabDespacho === 'despacho') ||
          (hayTiendaSeleccionada &&
            $datosModalDespacho.tabDespacho === 'retiro')
        "
        type="secondary"
        class="w-full"
        @click="guardarDatosModalDespacho"
        :disabled="!cumpleCondicion"
        >Continuar</Button
      >
      <picture
        class="hidden md:block"
        v-if="$datosModalDespacho.tabDespacho === 'despacho' && !$user"
      >
        <source
          type="image/webp"
          srcset="/assets/modalDespachoLogin/cuponDespacho.webp"
        />
        <img class="pt-8" src="/assets/modalDespachoLogin/cuponDespacho.png" />
      </picture>
      <ReferidoPor class="mt-5" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import SelectorDirecciones from "./SelectorDirecciones.vue";
  import SelectorDireccionesTiendas from "./SelectorDireccionesTiendas.vue";
  import CarruselVentanasDespacho from "./CarruselVentanasDespacho.vue";
  import TabsDespacho from "./TabsDespacho.vue";
  import Button from "@components/ui/Button.vue";
  import {
    guardarDatosModalDespacho,
    setearComponenteActivoModalDespachoLogin,
    setearTabRegistroLoginModalDespacho,
    tiposPedidoDisponiblesModalDespacho,
    datosModalDespacho,
  } from "@stores/despacho";
  import { useStore } from "@nanostores/vue";
  import CarruselVentanasRetiro from "./CarruselVentanasRetiro.vue";
  import MapaTiendasRetiro from "./MapaTiendasRetiro.vue";
  import { computed } from "@vue/runtime-core";
  import SelectorTiendas from "./SelectorTiendas.vue";
  import IngresarDireccion from "./IngresarDireccion.vue";
  import { user } from "@stores/user";
  import BannerFrestarDisclosure from "@components/despacho/BannerFrestarDisclosure.vue";
  import ReferidoPor from "../ReferidoPor.vue";

  const $datosModalDespacho = useStore(datosModalDespacho);
  const $user = useStore(user);

  const hayDireccion = computed(() => {
    if ($user.value && $user.value.direcciones.length > 0) {
      return true;
    }
    if ($datosModalDespacho.value.direccion) {
      return true;
    }
    return false;
  });
  const iniciarSesion = () => {
    setearTabRegistroLoginModalDespacho("login");
    setearComponenteActivoModalDespachoLogin("RegistroLogin");
  };
  const hayTiendaSeleccionada = computed(() => {
    return $datosModalDespacho.value.tiendaSeleccionada;
  });

  const $tiposPedidoDisponiblesModalDespacho = useStore(
    tiposPedidoDisponiblesModalDespacho,
  );
  const cumpleCondicion = computed(() => {
    if ($datosModalDespacho.value.tabDespacho === "despacho") {
      //checkeamos si la dirección del usuario que está seleccionada tiene zonas de despacho disponibles y que esté seleccionada una ventana
      return (
        $datosModalDespacho.value.ventana &&
        $tiposPedidoDisponiblesModalDespacho.value.some(
          (tipoPedido) => tipoPedido.tiene_despacho == true,
        )
      );
    }
    //checkeamos si la tienda que está seleccionada tiene una ventana seleccionada
    return $datosModalDespacho.value.ventana;
  });
</script>
