<template>
  <div>
    <Flicking
      :options="options"
      :plugins="pluginsCarruselSelectorTiendas"
      class="mb-5"
      ref="carruselSelectorTiendas"
    >
      <div
        class="flex flex-col gap-2 mb-10 mx-4"
        v-for="(tiendas, index) in tiendasAgrupadas"
        :key="index"
      >
        <CardTienda v-for="tienda in tiendas" :tienda="tienda" />
      </div>
      <template #viewport>
        <Paginador
          class="-bottom-2"
          :carrusel="carruselSelectorTiendas"
          :nombre-paginador="nombrePaginadorCarruselSelectorTiendas"
          :background="false"
        />
      </template>
    </Flicking>
    <div class="flex flex-col items-center gap-3">
      <Button
        type="secondary"
        class="w-full"
        :disabled="!$datosModalDespacho.tiendaPreSeleccionada"
        @click="seleccionarTienda"
        >{{ textoContinuar }}</Button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed } from "@vue/runtime-core";
  import { useStore } from "@nanostores/vue";
  import CardTienda from "./CardTienda.vue";
  import Button from "@components/ui/Button.vue";
  import {
    datosModalDespacho,
    setearTiendaModalDespacho,
    seleccionarPrimeraVentanaRetiroDisponibleModalDespacho,
    setearComponenteActivoModalDespachoLogin,
    guardarDatosModalDespacho,
    tiendasConRetiro,
    setearTipoPedidoModalDespacho,
    tiposPedido,
  } from "@stores/despacho";
  import Flicking from "@egjs/vue3-flicking";
  import { user } from "@stores/user";
  import Paginador from "@components/ui/Paginador.vue";
  import { plugins } from "@lib/bullets";

  const options = {
    align: "center",
    preventDefaultOnDrag: true,
    panelsPerView: 1,
  };
  const carruselSelectorTiendas = ref<Flicking>();
  const nombrePaginadorCarruselSelectorTiendas =
    "paginador-carrusel-selector-tiendas";
  const pluginsCarruselSelectorTiendas = plugins(
    nombrePaginadorCarruselSelectorTiendas,
    false,
  );

  const $tiendasConRetiro = useStore(tiendasConRetiro);
  const $datosModalDespacho = useStore(datosModalDespacho);
  const $tiposPedido = useStore(tiposPedido);

  const $user = useStore(user);

  const tiendasPorPanel = 3;

  const seleccionarTienda = () => {
    if (!$datosModalDespacho.value.tiendaPreSeleccionada) return;
    setearTiendaModalDespacho($datosModalDespacho.value.tiendaPreSeleccionada);
    const tipoPedido = $tiposPedido.value.find((tipo) => tipo.id === 3);
    if (!tipoPedido) return;
    setearTipoPedidoModalDespacho(tipoPedido);
    seleccionarPrimeraVentanaRetiroDisponibleModalDespacho();
    guardarDatosModalDespacho();
    if (!$user.value) {
      return setearComponenteActivoModalDespachoLogin("RegistroLogin");
    }
  };

  const tiendasAgrupadas = computed(() => {
    const tiendas = $tiendasConRetiro.value;
    const tiendasAgrupadas = [];
    for (let i = 0; i < tiendas.length; i += tiendasPorPanel) {
      tiendasAgrupadas.push(tiendas.slice(i, i + tiendasPorPanel));
    }
    return tiendasAgrupadas;
  });

  const textoContinuar = computed(() => {
    if (!$user.value) return "Continuar";
    return "Ver horarios";
  });
</script>
