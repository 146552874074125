<template>
  <div>
    <h1 class="mb-4 text-center text-xl font-semibold leading-7">
      Edita tu dirección
    </h1>
    <p
      class="mb-6 text-center text-base font-normal leading-5 text-gray-700 sm:mx-16"
    >
      Recuerda que tienes total libertad para editar tus direcciones guardadas
      de despacho de forma sencilla y conveniente.
    </p>
    <IngresarDireccion />
  </div>
</template>
<script setup lang="ts">
  import IngresarDireccion from "./IngresarDireccion.vue";
</script>
