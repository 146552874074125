<template>
  <div class="flex flex-col items-center gap-2 sm:px-24 sm:pb-4">
    <img
      src="/assets/checkout/carrito-saltarin.gif"
      alt="carrito saltarin"
      class="h-36"
    />
    <h2 class="text-2xl mb-2 font-semibold">Cambios en tu carro</h2>
    <p class="text-sm mb-4 text-center">
      Si continúas habrán cambios en tu carrito y podrás verlos en el siguiente
      paso, en caso de que no quieras generar cambios cancela la acción.
    </p>

    <Button
      type="primary"
      size="l"
      @click="solucionarProblemas"
      :loading="loading"
    >
      Solucionar problemas
    </Button>
    <Button class="border-0" size="l" @click="mantenerDespacho">
      {{ mensajeMantener }}
    </Button>
  </div>
</template>
<script setup lang="ts">
  import type { Direccion } from "@lib/interfaces";
  import { useStore } from "@nanostores/vue";
  import {
    cerrarModalDespachoLogin,
    datosModalDespacho,
    setearDespacho,
    tipoModificacion,
    setearLoaderCarruselVentanas,
  } from "@stores/despacho";
  import direcionesTiendaJson from "@data/direccionesTiendas.json";
  import Button from "@components/ui/Button.vue";
  import { ref, computed, onUnmounted } from "@vue/runtime-core";
  import type { DireccionesTienda } from "@lib/interfaces";
  const $datosModalDespacho = useStore(datosModalDespacho);
  const $tipoModificacion = useStore(tipoModificacion);
  const direcionesTienda: DireccionesTienda = direcionesTiendaJson;
  const loading = ref<boolean>(false);

  const mantenerDespacho = () => {
    setearLoaderCarruselVentanas(false);
    cerrarModalDespachoLogin();
  };

  const solucionarProblemas = () => {
    {
      loading.value = true;
      if (!$datosModalDespacho.value.ventana) {
        loading.value = false;
        return;
      }
      if (!$datosModalDespacho.value.tipoPedido) {
        loading.value = false;
        return;
      }
      let direccion: Direccion | undefined = undefined;
      if ($datosModalDespacho.value.tipoPedido.id == 3) {
        //Logica de direccion de tienda para retiro en tienda
        direccion =
          direcionesTienda[$datosModalDespacho.value.ventana.bodega_id];
      } else if ($datosModalDespacho.value.direccion) {
        direccion = $datosModalDespacho.value.direccion;
      } else {
        loading.value = false;
        return;
      }

      setearDespacho(
        $datosModalDespacho.value.ventana,
        $datosModalDespacho.value.tipoPedido.id,
        direccion,
      );

      document.location.href = "/problemas";
      loading.value = false;
    }
  };

  const mensajeMantener = computed(() => {
    return $tipoModificacion.value === "despacho"
      ? "Mantener tipo de despacho"
      : "Mantener día y hora de despacho";
  });

  onUnmounted(() => {
    setearLoaderCarruselVentanas(false);
  });
</script>
